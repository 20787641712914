import React from 'react';

function Footer(props) {
    return (
        <div className='footer'>
            <p>© Programming with Sinework</p>
        </div>
    );
}

export default Footer;